<template>
  <div class="flex h-full flex-col items-center justify-center">
    <div class="w-full max-w-md">
      <PvCard
        :header-bg-variant="isNonProductionEnvironment ? 'warning' : 'default'"
      >
        <template #title>
          Sign in to BotAdmin
        </template>
        <template #content>
          <PvMessage
            v-if="!loginInProgress && (errorCode || aadError)"
            severity="error"
          >
            <template v-if="errorCode === 401">
              Unauthorized: Username / password combination wrong.
            </template>
            <template v-if="errorCode === 500">
              Server error!
            </template>
            <div v-if="aadError === 'unexpected_error' || aadError === 'wrong_state'">
              An unexpected error occurred while trying to sign in.
            </div>
            <div v-if="aadError === 'not_allowed'">
              The user you signed in with is not authorized for the BotAdmin dashboard.
            </div>
            <div v-else>
              Something went wrong. Please try again later.
            </div>
          </PvMessage>
          <form @submit.prevent="doLogin">
            <SwFormGroup
              id="username-group"
              label="Username"
              label-for="username"
              label-size="sm"
            >
              <PvInputText
                id="username"
                v-model="input.username"
                autocomplete="username"
                autofocus
                placeholder="Username"
                required
                type="text"
              />
            </SwFormGroup>
            <SwFormGroup
              id="password-group"
              label="Password"
              label-for="password"
              label-size="sm"
            >
              <PvInputText
                id="password"
                v-model="input.password"
                autocomplete="current-password"
                required
                placeholder="Password"
                type="password"
              />
            </SwFormGroup>
            <PvButton
              class="mb-2 w-full"
              label="Sign in"
              severity="primary"
              type="submit"
              :loading="loginInProgress"
            />
          </form>
          <div v-if="ssoEnabled">
            <a
              :href="ssoPath"
              class="block text-center"
            >
              Sign in with your organization account
            </a>
          </div>
          <div>
            <a
              href="accounts/password_reset"
              class="block text-center"
            >
              Reset your BotAdmin password
            </a>
          </div>
        </template>
        <template #footer>
          <div class="mt-4 text-center">
            <img
              class="w-24"
              src="../assets/supwiz_dark_text.svg"
              alt="SupWiz Logo"
            >
          </div>
        </template>
      </PvCard>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import { getBotAdminEnvironment } from '@/js/utils';
import { endpoints } from '@/backend/endpoints';

export default {
  name: 'Login',
  data() {
    return {
      input: {
        username: '',
        password: '',
      },
      loginInProgress: false,
      ssoEnabledResponse: false,
    };
  },
  computed: {
    ...mapGetters({
      errorCode: 'auth/errorCode',
    }),
    ssoEnabled() {
      return this.ssoEnabledResponse;
    },
    ssoPath() {
      return endpoints.ssoLogin;
    },
    aadError() {
      return this.$route.query.aad_error;
    },
    isNonProductionEnvironment() {
      // This really is not a computed property; it will never realistically change during runtime.
      return getBotAdminEnvironment().toLowerCase() !== 'production';
    },
    botadminEnvironment() {
      return getBotAdminEnvironment();
    },
  },
  async created() {
    const response = await axios.get(endpoints.ssoEnabled);
    this.ssoEnabledResponse = response.data.toLowerCase() === 'true';
  },
  methods: {
    ...mapActions('auth', [
      'login',
    ]),
    async doLogin() {
      const { username, password } = this.input;
      this.loginInProgress = true;
      try {
        const { success } = await this.login({ username, password });
        if (success) {
          this.$router.push({ name: 'manage' });
        }
      } finally {
        this.loginInProgress = false;
      }
    },
  },
};
</script>
