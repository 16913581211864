import axios from 'axios';
import { deepEqualsJson } from 'supwiz/util/data';
import { ChatSystemManipulationAction } from '@/enums/chatsystemStatus';
import { authHeader } from '@/helpers/authHeader';
import { endpoints } from '@/backend/endpoints';
import botBackend from '@/backend/botManipulationBackend';

const voicePlatforms = ['vonage', 'vonage-puzzel', 'vonage-trio', 'audiocodes'];

const initialState = {
  chatsystemsState: [],
  chatsystem: null,
  queues: {},
  defaultErrorHandlers: [],
  mustMatchLanguage: false,
  mustMatchNamedLabel: false,
  lastUpdatedTimestamp: 0,
  isUpdating: false,
  updatedField: [],
  defaultQueueDefinition: {
    labels: [],
    errorHandlers: [],
  },
};

const getters = {
  chatsystemsState: (state) => state.chatsystemsState,
  chatsystem: (state) => state.chatsystem,
  getQueues: (state) => state.queues,
  getDefaultErrorHandlers: (state) => state.defaultErrorHandlers,
  getLastUpdatedTimestamp: (state) => state.lastUpdatedTimestamp,
  getMustMatchLanguage: (state) => state.mustMatchLanguage,
  getMustMatchNamedLabel: (state) => state.mustMatchNamedLabel,
  isVoiceBot(state) {
    return voicePlatforms.includes(state.chatsystem);
  },
  getQueueDefinition: (state) => state.defaultQueueDefinition,
};

const mutations = {
  setChatsystemsState(state, { newState }) {
    for (const field of ['restart_time', ...state.updatedField]) {
      const oldValue = state.chatsystemsState[0] && state.chatsystemsState[0][field];
      const newValue = newState && newState[0] && newState[0][field];
      if (oldValue !== newValue) {
        state.updatedField = [];
        state.isUpdating = false;
        break;
      }
    }
    state.chatsystemsState = newState;
  },
  setChatsystem(state, { chatsystem }) {
    state.chatsystem = chatsystem;
  },
  setQueues(state, { newState }) {
    if (deepEqualsJson(newState, state.queues)) {
      return;
    }
    state.queues = newState;
  },
  setDefaultErrorHandlers(state, { newState }) {
    if (deepEqualsJson(newState, state.defaultErrorHandlers)) {
      return;
    }
    state.defaultErrorHandlers = newState;
  },
  setMustMatchLanguage(state, { newState }) {
    if (state.mustMatchLanguage === newState) {
      return;
    }
    state.mustMatchLanguage = !!newState;
  },
  setMustMatchNamedLabel(state, { newState }) {
    if (state.mustMatchNamedLabel === newState) {
      return;
    }
    state.mustMatchNamedLabel = !!newState;
  },
  setLastUpdatedTimestamp(state, { timestamp }) {
    state.lastUpdatedTimestamp = timestamp;
  },
  setUpdatedField(state, value) {
    state.updatedField.push(value);
    state.isUpdating = true;
  },
};

const actions = {
  async sendRoutingConfig(state, { queues, defaultErrorHandlers, options }) {
    // Wait for control to confirm them
    await botBackend.sendRoutingConfig(queues, defaultErrorHandlers, options);
  },
  async sendChatSystemSettings({ dispatch }, { settings }) {
    // Wait for control to confirm them
    try {
      await botBackend.sendChatSystemSettings(settings);
    } catch (error) {
      dispatch('templateStore/templateSendNotification', {
        title: 'Failed to update support system settings',
        text: error.response.data.error,
        severity: 'error',
        toast: true,
      }, { root: true });
    }
  },
  async activateChatSystem({ commit }) {
    const data = { action: ChatSystemManipulationAction.ACTIVATE_CHATSYSTEM };
    const config = { headers: authHeader() };
    try {
      commit('setUpdatedField', 'availability');
      await axios.post(endpoints.chatSystemManipulation, data, config);
    } catch {
      console.log('Failed to activate support system');
    }
  },
  async deactivateChatSystem({ commit }) {
    const data = { action: ChatSystemManipulationAction.DEACTIVATE_CHATSYSTEM };
    const config = { headers: authHeader() };
    try {
      commit('setUpdatedField', 'availability');
      await axios.post(endpoints.chatSystemManipulation, data, config);
    } catch {
      console.log('Failed to deactivate support system');
    }
  },
  async resetChatSystemConnection({ commit }) {
    const data = { action: ChatSystemManipulationAction.RESET_CHATSYSTEM_CONNECTION };
    const config = { headers: authHeader() };
    try {
      commit('setUpdatedField', 'restart_time');
      await axios.post(endpoints.chatSystemManipulation, data, config);
    } catch {
      console.log('Failed to reset support system connection');
    }
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
