import { createSsoEndpoints } from 'supwiz/util/sso';

function getBaseRESTAPIURL() {
  if (process.env.NODE_ENV !== 'development') {
    return `${window.botadminPublicPath}api`;
  }
  return '/api';
}

function getSocketURL() {
  if (process.env.NODE_ENV !== 'development') {
    return `wss://${window.location.host}${window.botadminPublicPath}api/ws/`;
  }
  return `wss://${window.location.host}/api/ws/`;
}

const BASE_URL = getBaseRESTAPIURL();

const endpoints = {
  ...createSsoEndpoints(BASE_URL),
  alterBot: `${BASE_URL}/bot/alter/`,
  deployBot: `${BASE_URL}/bot/deploy/`,
  workerState: `${BASE_URL}/worker/state/`,
  workerCommand: `${BASE_URL}/worker/command/`,
  chatSystemManipulation: `${BASE_URL}/chatsystem/manipulation/`,
  chatSystemSettings: `${BASE_URL}/chatsystem/settings/`,
  speechSettings: `${BASE_URL}/speech/settings/`,
  speechCommand: `${BASE_URL}/speech/command/`,
  smsSettings: `${BASE_URL}/sms/settings/`,
  emailSettings: `${BASE_URL}/email/settings/`,
  aiEngineSettings: `${BASE_URL}/ai-engine/settings/`,
  routingConfig: `${BASE_URL}/routing-config/`,
  logs: `${BASE_URL}/logs/`,
  stagedBotInfo: `${BASE_URL}/staged-bot-info/`,
  supSearchSettings: `${BASE_URL}/supsearch/settings/`,
  variantSetInfo: `${BASE_URL}/variant-set-info/`,
  userDetails: `${BASE_URL}/user-details/`,
  controlManipulation: `${BASE_URL}/control/manipulation/`,
  groups: `${BASE_URL}/groups/`,
};

export {
  getBaseRESTAPIURL,
  getSocketURL,
  endpoints,
};
