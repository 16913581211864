<template>
  <div class="flex flex-shrink-0 rounded-md bg-white/20 relative">
    <div class="absolute left-3 top-0 bottom-0 text-white flex items-center pointer-events-none">
      <FontAwesomeIcon
        icon="magnifying-glass"
      />
    </div>
    <input
      v-model="valueToInput"
      :aria-label="localizationObj.sidebar.search.searchPages"
      class="w-full template-fontsize-1 bg-transparent border-0 p-2 pl-11 pr-6 text-white"
      type="text"
      @keyup.esc="resetQuery"
      @focus="$event.target.select()"
    >
    <button
      v-if="modelValue"
      class="absolute right-0 px-2 top-0 bottom-0 text-white flex
        items-center bg-transparent border-0 cursor-pointer"
      type="button"
      :title="localizationObj.sidebar.search.clearSearch"
      @click="resetQuery"
    >
      <FontAwesomeIcon
        icon="times"
      />
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SidebarSearch',
  props: {
    modelValue: { type: String, required: true },
  },
  emits: ['update:modelValue'],
  computed: {
    ...mapState('templateStore', ['localizationObj']),
    valueToInput: {
      get() { return this.modelValue; },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    resetQuery() {
      this.valueToInput = '';
    },
  },
};
</script>
