// I'm declaring a separate file to deal with icons, such that A) we can prevent main.js from being
// bloated with icons configuration code, and you have an appropriately named file to deal with it.
import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faCheck, faPaste, faStopCircle, faUser, faDesktop,
  faLink, faCopy, faSkullCrossbones, faTrashAlt, faWrench, faList, faInfo,
  faBell, faTimes, faBars, faRoute, faPlus, faCompressAlt, faCog, faCubes,
  faEye, faEyeSlash, faExclamationCircle, faCogs, faMicrophone, faCheckCircle,
  faCircle, faTimesCircle, faInfoCircle, faAngleDown,
  faMagnifyingGlass, faSpinner, faEdit,
} from '@fortawesome/free-solid-svg-icons';

import {
  faTimesCircle as farFaTimesCircle,
} from '@fortawesome/free-regular-svg-icons';

library.add(
  faBars,
  faBell,
  faAngleDown,
  faCheck,
  faCheckCircle,
  faCircle,
  faCog,
  faCogs,
  faCompressAlt,
  faCopy,
  faCubes,
  faDesktop,
  faEdit,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faInfo,
  faInfoCircle,
  faLink,
  faList,
  faMicrophone,
  faPaste,
  faPlus,
  farFaTimesCircle,
  faRoute,
  faSkullCrossbones,
  faStopCircle,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUser,
  faWrench,
  faMagnifyingGlass,
  faSpinner,
);

export default library;
