<template>
  <div class="flex h-full flex-col items-center justify-center">
    <div class="max-w-sm text-center">
      <PvCard>
        <template #title>
          You are now signed out
        </template>
        <template #content>
          <RouterLink :to="{ name: 'login' }" class="my-4 block">
            Sign in again
          </RouterLink>
        </template>
        <template #footer>
          <div class="mt-4">
            <img
              class="w-24"
              src="../assets/supwiz_dark_text.svg"
              alt="SupWiz Logo"
            >
          </div>
        </template>
      </PvCard>
    </div>
  </div>
</template>

<script>
import { closeSocket } from '@/backend/socketBackend';

export default {
  name: 'LogoutPage',
  mounted() {
    this.$store.dispatch('auth/logout');
    closeSocket();
  },
};
</script>
