<template>
  <div
    id="app-template"
    class="w-full h-screen overflow-hidden flex flex-col"
  >
    <AppHeader>
      <ToolDetails />
      <AppBreadcrumbs v-if="breadcrumbs" />
      <div class="flex gap-2">
        <slot name="header" />
        <NotificationsCenter v-if="notificationsCenter" />
        <UserDetails v-if="isLoggedIn" />
      </div>
    </AppHeader>
    <div
      class="flex overflow-hidden w-full h-full"
    >
      <AppSidebar
        :show-search="sidebarSearch"
        :show-collapse-btn="showCollapseButton"
      >
        <template #before-nav>
          <slot name="before-nav" />
        </template>
        <template #after-nav>
          <slot name="after-nav" />
        </template>
      </AppSidebar>
      <div
        :class="[
          'h-full flex-auto overflow-auto p-3 relative bg-shade-100',
          { 'rounded-tl-2xl': sidebarVisible },
        ]"
      >
        <PageSpinner v-if="isLoading" />
        <slot name="page-content" />
      </div>
    </div>
    <PvConfirmDialog />
    <PvConfirmPopup group="popup" />
    <PvToast />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import localizationObj from './localization';

import AppHeader from './header/AppHeader.vue';
import AppBreadcrumbs from './header/Breadcrumbs.vue';
import AppSidebar from './sidebar/AppSidebar.vue';
import PageSpinner from './PageSpinner.vue';
import NotificationsCenter from './header/NotificationsCenter.vue';
import UserDetails from './header/UserDetails.vue';
import ToolDetails from './header/ToolDetails.vue';

export default {
  name: 'AppTemplate',
  components: {
    AppHeader,
    AppBreadcrumbs,
    AppSidebar,
    PageSpinner,
    NotificationsCenter,
    UserDetails,
    ToolDetails,
  },
  props: {
    pages: {
      type: Array,
      default: () => [],
    },
    userDetails: {
      type: Object,
      default: () => ({}),
    },
    userOptionPages: {
      type: Array,
      default: () => ([]),
    },
    noMainPage: {
      type: Boolean,
      default: false,
    },
    showSidebar: {
      type: Boolean,
      default: false,
    },
    sidebarSearch: {
      type: Boolean,
      default: false,
    },
    showSpinner: {
      type: Boolean,
      default: false,
    },
    notificationsCenter: {
      type: Boolean,
      default: false,
    },
    breadcrumbs: {
      type: Boolean,
      default: true,
    },
    loggedIn: {
      type: Boolean,
      default: false,
    },
    toolDetails: {
      type: Object,
      default: () => ({}),
    },
    showCollapseButton: {
      type: Boolean,
      default: true,
    },
    localizationObj: {
      type: Object,
      default: () => (localizationObj),
    },
  },
  computed: {
    ...mapState('templateStore', ['isLoading', 'sidebarVisible', 'isLoggedIn']),
  },
  watch: {
    pages: {
      immediate: true,
      handler(value) {
        this.$store.commit('templateStore/templateSetPages', value);
      },
    },
    userDetails: {
      immediate: true,
      handler(value) {
        this.$store.commit('templateStore/templateSetUserDetails', value);
      },
    },
    userOptionPages: {
      immediate: true,
      handler(value) {
        this.$store.commit('templateStore/templateSetUserOptionPages', value);
      },
    },
    showSidebar: {
      immediate: true,
      handler(value) {
        this.$store.commit('templateStore/templateSetSidebarVisible', value);
      },
    },
    localizationObj: {
      immediate: true,
      handler(value) {
        this.$store.commit('templateStore/templateSetLocalizationObj', value);
      },
    },
    loggedIn: {
      immediate: true,
      handler(value) {
        this.$store.commit('templateStore/templateSetIsLoggedIn', value);
      },
    },
    noMainPage: {
      immediate: true,
      handler(value) {
        this.$store.commit('templateStore/templateSetNoMainPage', value);
      },
    },
    showSpinner: {
      immediate: true,
      handler(value) {
        this.$store.commit('templateStore/templateSetIsLoading', value);
      },
    },
    toolDetails: {
      immediate: true,
      handler(value) {
        this.$store.commit('templateStore/templateSetToolDetails', value);
      },
    },
  },
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
  overflow: hidden; /* helps positioning tooltips */
}
#app-template {
  background-size: 360px;
  background-position: top left;
}
.scrollbar-slim {
  scrollbar-width: thin;
}
.scrollbar-slim::-webkit-scrollbar {
  width: 10px;
}
.scrollbar-slim::-webkit-scrollbar-track {
  background: var(--secondary);
  border-radius: 0.25rem;
}
.scrollbar-slim::-webkit-scrollbar-thumb {
  background-color: var(--light);
  border-radius: 0.25rem;
}
.scrollbar-sidebar {
  scrollbar-width: thin;
  scrollbar-color: transparent #ffffff40;
}
.scrollbar-sidebar::-webkit-scrollbar {
  width: 10px;
}
.scrollbar-sidebar::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 0.25rem;
}
.scrollbar-sidebar::-webkit-scrollbar-thumb {
  background-color: #ffffff40;
  border-radius: 0.25rem;
}
.custom-control-label{
  font-size: 0.9rem;
}
th{
  white-space: nowrap;
}
td{
  word-break: break-all;
  vertical-align: middle !important;
}
/* Use this class to keep table data intact, otherwise the words will be broken */
.table-nobreak{
  word-break: keep-all;
}
.delete-column{
  width: 35px !important;
}

/* vue-json-pretty styling */
.vjs-value{
  white-space:normal;
  word-break:break-all;
}
.vjs-value__string{
  color:#005f89 !important;
}

.ease-in-out {
  transition: all .25s ease-in-out;
}
.btn-invis,
.btn-invis:active,
.btn-invis:focus,
.btn-invis:hover {
  outline: none!important;
  appearance: none!important;
  border-color: transparent!important;
  background: transparent!important;
  box-shadow: none!important;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.cursor-pointer {
  cursor: pointer;
}
.r-75 { border-radius:.75rem!important }
.r-50 { border-radius:.5rem!important }
.r-25 { border-radius:.25rem!important }
.r-0 { border-radius:0rem!important }

.r-75-left { border-radius:.75rem 0 0 .75rem!important }
.r-50-left { border-radius:.50rem 0 0 .50rem!important }
.r-25-left { border-radius:.25rem 0 0 .25rem!important }

.r-75-right { border-radius:0 .75rem .75rem 0!important }
.r-50-right { border-radius:0 .50rem .50rem 0!important }
.r-25-right { border-radius:0 .25rem .25rem 0!important }

.content-fill{
  height: calc(100vh - 60px - 2rem);
  min-height: calc(100vh - 60px - 2rem);
  max-height: calc(100vh - 60px - 2rem);
}
.template-fontsize-1 { font-size: .95rem }
.template-fontsize-2 { font-size: 1.05rem }
.template-fontsize-3 { font-size: .80rem }
</style>
