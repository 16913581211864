<template>
  <PvButton
    v-bind="$attrs"
    :type="type"
    :pt="{
      root: () => ({
        class: [
          props,
          'border-0',
          $options.baseButtonClasses,
        ],
      }),
    }"
    @click="$emit('click', $event)"
  >
    <slot />
  </PvButton>
</template>

<script>
import {
  baseTransitionClasses,
  baseButtonClasses,
  baseButtonHoverClasses,
  baseTextClasses,
  baseTextHoverClasses,
} from '../constants';

export default {
  name: 'ButtonBase',
  baseButtonClasses: [
    baseTransitionClasses,
    baseButtonClasses,
    baseButtonHoverClasses,
    baseTextClasses,
    baseTextHoverClasses,
  ].join(' '),
  props: {
    type: {
      type: String,
      default: 'button',
    },
  },
  emits: ['click'],
};
</script>
