import axios from 'axios';
import { authHeader } from '@/helpers/authHeader';
import { endpoints } from '@/backend/endpoints';

function sendChatSystemSettings(settings) {
  const payload = {
    settings,
  };
  const auth = authHeader();
  return axios({
    url: endpoints.chatSystemSettings,
    data: payload,
    method: 'post',
    headers: auth,
  });
}

function sendSpeechSettings(settings) {
  const payload = {
    settings,
  };
  const auth = authHeader();
  return axios({
    url: endpoints.speechSettings,
    data: payload,
    method: 'post',
    headers: auth,
  });
}

function sendSpeechCommand(command) {
  const payload = {
    command,
  };
  const auth = authHeader();
  return axios({
    url: endpoints.speechCommand,
    data: payload,
    method: 'post',
    headers: auth,
  });
}

function sendSmsSettings(settings) {
  const payload = {
    settings,
  };
  const auth = authHeader();
  return axios({
    url: endpoints.smsSettings,
    data: payload,
    method: 'post',
    headers: auth,
  });
}

function sendEmailSettings(settings) {
  const payload = {
    settings,
  };
  const auth = authHeader();
  return axios({
    url: endpoints.emailSettings,
    data: payload,
    method: 'post',
    headers: auth,
  });
}

function sendAIEngineSettings(settings) {
  const payload = {
    settings,
  };
  const auth = authHeader();
  return axios({
    url: endpoints.aiEngineSettings,
    data: payload,
    method: 'post',
    headers: auth,
  });
}

function sendSupSearchSettings(settings) {
  const payload = {
    settings,
  };
  const auth = authHeader();
  return axios({
    url: endpoints.supSearchSettings,
    data: payload,
    method: 'post',
    headers: auth,
  });
}

function sendRoutingConfig(queues, defaultErrorHandlers, options) {
  const payload = {
    queues,
    defaultErrorHandlers,
    options,
  };
  const auth = authHeader();
  return axios({
    url: endpoints.routingConfig,
    data: payload,
    method: 'post',
    headers: auth,
  });
}

function requestWorkerState(workerId) {
  const payload = {
    workerId,
  };
  const auth = authHeader();
  return axios({
    url: endpoints.workerState,
    data: payload,
    method: 'post',
    headers: auth,
  });
}

function sendWorkerCommand(workerIds, command, commandArg) {
  const payload = {
    workerIds,
    command,
    commandArg,
  };
  const auth = authHeader();
  return axios({
    url: endpoints.workerCommand,
    data: payload,
    method: 'post',
    headers: auth,
  });
}

function alterBotStatus(botsToUpdate, action) {
  const payload = {
    bots: botsToUpdate,
    action,
  };
  const auth = authHeader();
  return axios({
    url: endpoints.alterBot,
    data: payload,
    method: 'post',
    headers: auth,
  });
}

function makeBotActive(botsToActivate) {
  return alterBotStatus(botsToActivate, 'activate');
}

function makeBotInactive(botsToDeactivate) {
  return alterBotStatus(botsToDeactivate, 'deactivate');
}

function deployBot(payload) {
  const auth = authHeader();
  return axios({
    url: endpoints.deployBot,
    data: payload,
    method: 'post',
    headers: auth,
  });
}

const restCalls = {
  makeBotActive,
  makeBotInactive,
  deployBot,
  requestWorkerState,
  sendWorkerCommand,
  sendChatSystemSettings,
  sendSpeechSettings,
  sendSpeechCommand,
  sendSmsSettings,
  sendEmailSettings,
  sendAIEngineSettings,
  sendSupSearchSettings,
  sendRoutingConfig,
};

export default restCalls;
