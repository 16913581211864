<template>
  <div class="relative">
    <ButtonBase
      aria-haspopup
      aria-controls="user-details-menu"
      class="size-9 p-0 justify-center font-bold"
      @click="toggleMenu"
    >
      <img
        v-if="userDetails.avatar"
        :src="userDetails.avatar"
        class="w-full h-auto"
        alt="Avatar"
      >
      <template v-else>
        {{ userInitials }}
      </template>
    </ButtonBase>
    <PvMenu
      id="user-details-menu"
      ref="menu"
      popup
      :model="userMenuOptions"
    >
      <template #start>
        <span class="block py-3 px-3">
          {{ displayName !== localizationObj.userMenu.noName
            ? `${localizationObj.userMenu.greeting}, ${displayName}`
            : displayName }}
        </span>
      </template>
    </PvMenu>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ButtonBase from '../shared/components/ButtonBase.vue';

export default {
  name: 'UserDetails',
  components: { ButtonBase },
  computed: {
    ...mapState('templateStore', ['userDetails', 'localizationObj', 'userOptionPages']),
    userInitials() {
      if (!this.displayName) {
        return '?';
      }
      const split = this.displayName.split(' ');
      if (split.length > 1) {
        return `${split[0][0]}${split[1][0]}`.toUpperCase();
      }
      return split[0][0].toUpperCase() || '?';
    },
    displayName() {
      const { displayName } = this.userDetails;
      if (!displayName) return this.localizationObj.userMenu.noName;
      const regExp = /\(([^)]+)\)/;
      if (regExp.test(displayName)) {
        const matches = regExp.exec(displayName);
        return matches[1];
      }
      return this.userDetails.displayName;
    },
    userMenuOptions() {
      return [
        ...this.userOptionPages,
        {
          label: this.localizationObj.userMenu.logOut,
          command: () => {
            this.$router.push({ name: 'logout' });
          },
        },
      ];
    },
  },
  methods: {
    toggleMenu(event) {
      this.$refs.menu.toggle(event);
    },
  },
};
</script>
