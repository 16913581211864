<template>
  <div
    :class="[{
      'min-w-60 max-w-60': !sidebarCompact,
    }, 'relative flex items-center px-3']"
  >
    <div
      class="object-cover flex content-center min-w-10 max-w-10
        items-center overflow-hidden mr-2"
    >
      <img
        :src="toolDetails.logo"
        class="w-full h-auto"
        :alt="`${toolDetails.name} logo`"
        :title="`${toolDetails.name} logo`"
      >
    </div>
    <div
      v-if="!sidebarCompact"
      class="hidden sm:flex flex-col flex-grow overflow-hidden content-center"
    >
      <h2
        class="opacity-50 m-0 template-fontsize-2 text-white font-light"
      >
        {{ toolDetails.name }}
      </h2>
      <span
        v-if="emphasizeEnvironment"
        class="template-fontsize-3 text-white bg-red-500 leading-5 rounded-md text-center"
        :severity="envIndicatorBg"
      >
        {{ toolVersionEnv }}
      </span>
      <span
        v-else-if="toolDetails.version"
        class="opacity-50 m-0 template-fontsize-3 text-white"
      >
        {{ toolDetails.version }}
      </span>
    </div>
    <div
      v-if="toolDetails.env"
      class="fixed top-0 left-0 right-0 h-1 uppercase"
      :class="envIndicatorBg"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'LogoText',
  computed: {
    ...mapState('templateStore', ['toolDetails', 'sidebarCompact']),
    toolVersionEnv() {
      const { version, env } = this.toolDetails;
      if (version) return version;
      if (env) return env;
      return '';
    },
    emphasizeEnvironment() {
      const { env } = this.toolDetails;
      return /test|dev/i.test(env);
    },
    envIndicatorBg() {
      const env = this.toolDetails.env ? this.toolDetails.env.toLowerCase() : '';
      if (env.includes('test')) return 'bg-warning-500';
      if (env.includes('dev')) return 'bg-red-500';
      return '';
    },
  },
};
</script>
