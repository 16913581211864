<template>
  <nav class="absolute left-0 right-0 px-3">
    <PvTieredMenu
      :model="items"
      :pt="{
        root: () => ({
          class: 'bg-transparent border-0 p-0 text-white',
        }),
        menu: () => ({
          class: 'size-10 mb-2 bg-transparent',
        }),
        submenu: () => ({
          class: 'z-50',
        }),
        content: ({ props }) => {
          if (props.level !== 0) return { class: 'bg-transparent' }
          // top level
          return {
            class: [
              'bg-transparent has-[.active]:bg-black/30',
              $options.baseButtonHoverClasses,
            ],
          }
        },
      }"
    >
      <template #item="{ item, props }">
        <h4 v-if="item.parentLabel" class="block px-3 py-2 m-0 text-md">
          {{ item.parentLabel }}
        </h4>
        <router-link
          v-else-if="item.to"
          v-slot="{ href, navigate, isExactActive }"
          :to="item.to"
          custom
        >
          <a
            v-ripple
            :class="[
              'flex items-center rounded-md',
              {
                'bg-black/30': item.iconOnly && isExactActive,
                [$options.baseTextClasses]: item.iconOnly,
                [$options.baseTextHoverClasses]: item.iconOnly,
                'text-bold active': !item.iconOnly && isExactActive,
              },
            ]"
            :href="href"
            v-bind="props.action"
            @click="navigate"
          >
            <FontAwesomeIcon v-if="item.icon" :icon="item.icon" />
            <span v-if="!item.iconOnly" class="ml-2">{{ item.label }}</span>
          </a>
        </router-link>
        <a
          v-else
          v-ripple
          :class="[
            'flex items-center rounded-md',
            {
              [$options.baseTextClasses]: item.iconOnly,
              [$options.baseTextHoverClasses]: item.iconOnly,
            },
          ]"
          v-bind="props.action"
        >
          <FontAwesomeIcon v-if="item.icon" :icon="item.icon" />
          <span v-if="!item.iconOnly" class="ml-2">{{ item.label }}</span>
        </a>
      </template>
    </PvTieredMenu>
  </nav>
</template>
<script>

import {
  baseButtonClasses,
  baseButtonHoverClasses,
  baseTextClasses,
  baseTextHoverClasses,
  baseTransitionClasses,
} from '../../shared/constants';

export default {
  name: 'SidebarNavigation',
  baseButtonClasses,
  baseButtonHoverClasses,
  baseTextClasses,
  baseTextHoverClasses,
  baseTransitionClasses,
  props: {
    pages: {
      type: Array,
      required: true,
    },
  },
  computed: {
    items() {
      return this.pages
        .filter(({ separator }) => !separator)
        // trying to recreate the previous popover look
        .map((page) => ({
          ...page,
          iconOnly: true,
          items: page.items && [
            { parentLabel: page.label },
            { separator: true },
            ...page.items,
          ],
        }));
    },
  },
};
</script>
