<template>
  <div>
    <RouterView v-slot="{ Component, route }">
      <transition name="fade" mode="out-in">
        <component :is="Component" :key="route.path" />
      </transition>
    </RouterView>
    <PvDialog
      :block-scroll="true"
      :closable="false"
      :close-on-escape="false"
      :visible="promptForLogout"
      modal
    >
      The current session is timed out. Please sign in again.
      <template #footer>
        <PvButton
          autofocus
          label="Continue"
          severity="secondary"
          @click="doLogout"
        />
      </template>
    </PvDialog>
  </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';
import { initializeSocket } from '@/backend/socketBackend';

export default {
  name: 'LoggedUserBase',
  data() {
    return {
      socketReconnectionFailed: false,
      timeoutHandler: null,
    };
  },
  computed: {
    ...mapGetters('auth', ['tokenSeemsOk']),
    ...mapGetters('meta', [
      'socketIsConnected',
    ]),
    promptForLogout() {
      return !this.tokenSeemsOk || this.socketReconnectionFailed;
    },
  },
  watch: {
    socketIsConnected(newValue) {
      if (!newValue) {
        this.attemptReconnect([0, 1, 2, 3, 4].reverse());
      }
    },
  },
  created() {
    this.initializeSocket();
  },
  unmounted() {
    clearTimeout(this.timeoutHandler);
  },
  methods: {
    initializeSocket,
    ...mapActions('auth', [
      'logout',
    ]),
    doLogout() {
      this.logout();
      this.$router.push({
        name: 'logout',
      });
    },
    attemptReconnect(waitTimes) {
      // Attempt reconnect. If it fails, try again a number of times, until eventually forcing
      // user to login again.
      try {
        this.initializeSocket();
      } catch {
        if (waitTimes.length > 0) {
          // Schedule new attempt
          const whenToExecute = waitTimes.pop() * 1000;
          console.log(`waitTimes: ${waitTimes}, whenToExecute: ${whenToExecute}`);
          const self = this;
          setTimeout(
            () => self.attemptReconnect(waitTimes), whenToExecute,
          );
        } else {
          // Inform user that session is expired (and force him/her to logout)
          this.socketReconnectionFailed = true;
        }
      }
    },
  },
};
</script>
