<template>
  <ButtonBase
    v-bind="$attrs"
    class="flex items-center justify-center"
    @click="$emit('click', $event)"
  >
    <FontAwesomeIcon
      :icon="icon"
      :size="iconSize"
    />
  </ButtonBase>
</template>

<script>
import ButtonBase from './ButtonBase.vue';

export default {
  name: 'IconButton',
  components: { ButtonBase },
  inheritAttrs: false,
  props: {
    ariaLabel: {
      type: String,
      required: true, // no button text so we force an aria label
    },
    icon: {
      type: String,
      required: true,
    },
    iconSize: {
      type: [String, undefined],
      default: undefined,
    },
  },
  emits: ['click'],
};
</script>
